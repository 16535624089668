import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn from '../../layouts/TemplateColumn';
import { PageHeader, PageIntro, AnchorLinks, PageSection, RelatedContent, SecondaryCallout, Cutpoint, TextBlock, LogoCTAGrid, PageSubSection, TestingDefinition, TestingEquation, ReferencesBlock } from '../../components';
import { StaticImage } from 'gatsby-plugin-image';
import { cancerTypes } from '../../util/data/cancerTypes'
import { indications } from '../../util/data/indications'
import { jobCodes } from '../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `PD-L1 Testing Information | KEYTRUDA® (pembrolizumab) | HCP`,
    keywords: `pd-l1 testing, pd-l1 expression, cps score`,
    description: `Health care professionals may find information about PD-L1 testing, and how to evaluate PD-L1 expression based on combined positive score (CPS) and tumor proportion score (TPS).`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/biomarker-testing/pd-l1/","@type":"MedicalWebPage","name":"PD-L1 Testing & Scoring","description":"PD-L1 expression is evaluated differently depending on the type of cancer. Combined positive score (CPS) and tumor proportion score (TPS) both evaluate PD-L1 expression and help identify patients eligible for treatment with KEYTRUDA.1"}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/biomarker-testing/pd-l1/","@type":"MedicalAudience","audienceType":"health care professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

const jobCode = jobCodes[27].jobCode;

const relatedContent = [
    {
        section: 'Biomarker Testing',
        link: {
            url: '/biomarker-testing/biomarker-overview/',
            label: 'Biomarker Overview'
        }
    },
    {
        section: 'Biomarker Testing',
        link: {
            url: '/biomarker-testing/msi-mmr/',
            label: 'MSI/MMR Testing'
        }
    },
    {
        section: 'Resources',
        link: {
            url: '/resources/hcp-and-patient-resources/',
            label: 'HCP & Patient Resources'
        }
    }
]

const pageReferences = [
    {
        label: "1.",
        text: "Instructions for Use: PD&#8288;-&#8288;L1 IHC 22C3 pharmDx. Agilent Technologies, Inc. Revised October, 2023."
    },
    {
        label: "2.",
        text: "Instructions for Use: PD&#8288;-&#8288;L1 IHC 22C3 pharmDx. Agilent Technologies, Inc. Revised February, 2021."
    },
]

const anchorLinkModelData = [
    { label: 'Defining PD&#8288;-&#8288;L1 Testing' }, 
    { label: 'Indications for KEYTRUDA That Require PD&#8288;-&#8288;L1 Testing' }, 
]

const Page = ({ location }) => {

    return (
        <DefaultLayout pageMeta={pageMeta} location={location} jobCode={jobCode}>
            <div data-template-name="template-b">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-b-intro" maxWidth={824}>
                    <PageHeader title="PD&#8288;-&#8288;L1 Testing & Scoring" />
                    <PageSection title="Understanding PD&#8288;-&#8288;L1 Testing" bgColor="cloud">
                        <PageIntro>
                            <p>PD&#8288;-&#8288;L1 expression is evaluated differently depending on the type of cancer. Combined positive score (CPS) and tumor proportion score (TPS) both evaluate PD&#8288;-&#8288;L1 expression and help identify patients eligible for treatment with KEYTRUDA.<sup>1</sup></p>
                        </PageIntro>
                        <AnchorLinks items={anchorLinkModelData} />
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}
                {/* Begin Main Column */}
                <TemplateColumn id="template-b-main">
                    <PageSection title={anchorLinkModelData[0].label}>
                        <SecondaryCallout 
                            title="Biomarker status can be a road map for personalizing treatment" 
                            alt=""
                        />

                        <TestingDefinition 
                            title="TPS (Tumor Proportion Score)<sup>1</sup>" 
                            listHeading="TPS is used to assess PD&#8288;-&#8288;L1 expression in:" 
                            cancerList={['Advanced NSCLC']} 
                            definitions="PD&#8288;-&#8288;L1 = programmed death ligand 1; NSCLC = non–small cell lung cancer."
                        >
                            <h5>What Is TPS?</h5>
                            <p>This scoring method evaluates the percentage of viable tumor cells showing partial or complete membrane staining at any intensity.</p>
                            <h5>How to Determine TPS</h5>
                            <TestingEquation result="TPS (%)" numerator="# of PD&#8288;-&#8288;L1–positive tumor cells" denominator="Total # of PD&#8288;-&#8288;L1–positive + PD&#8288;-&#8288;L1–negative tumor cells" />
                            <h5>Interpretation of TPS Result</h5>
                            <p>PD&#8288;-&#8288;L1 expression level in advanced NSCLC is determined by the TPS, which is reported as a percentage on a scale of 0% to 100%.</p>
                            <p>A minimum of 100 viable tumor cells in the PD&#8288;-&#8288;L1–stained slide is required for the specimen to be considered adequate for PD&#8288;-&#8288;L1 evaluation.</p>
                        </TestingDefinition>

                        <TestingDefinition 
                            title="CPS (Combined Positive Score)<sup>1,2</sup>" 
                            listHeading="CPS is used to assess PD&#8288;-&#8288;L1 expression in:" 
                            cancerList={['Metastatic or unresectable, recurrent HNSCC', 'Advanced esophageal or GEJ carcinoma (2L + monotherapy)', 'Advanced cervical cancer', 'Advanced triple-negative breast cancer']}
                            definitions="HNSCC = head and neck squamous cell carcinoma; GEJ = gastroesophageal junction; 2L = second line."
                        >
                            <h5>What Is CPS?</h5>
                            <p>This scoring method evaluates the number of PD&#8288;-&#8288;L1–staining cells (tumor cells, lymphocytes, macrophages) relative to all viable tumor cells.</p>
                            <h5>How to Determine CPS</h5>
                            <TestingEquation result="CPS" numerator="# of PD&#8288;-&#8288;L1–positive cells" denominator="Total # of PD&#8288;-&#8288;L1–positive + PD&#8288;-&#8288;L1–negative tumor cells" />
                            <h5>Interpretation of CPS Result</h5>
                            <p>Although the result of the CPS calculation can exceed 100, the maximum score is defined as CPS 100.</p>
                            <p>A minimum of 100 viable tumor cells in the PD&#8288;-&#8288;L1–stained slide is required for the specimen to be considered adequate for PD&#8288;-&#8288;L1 evaluation.</p>
                        </TestingDefinition>

                    </PageSection>

                    <PageSection bgColor="cloud" title={anchorLinkModelData[1].label}>
                        {/* LUNG – SubSection title to remain hard-coded because the full NSCLC cancerType is broader than just these 2 indications*/}
                        <PageSubSection title="Advanced Non&#8288;–&#8288;Small Cell Lung Cancer (NSCLC)">
                            <Cutpoint indicationId={6} cutpoint="TPS ≥1%" />
                            <Cutpoint indicationId={7} cutpoint="TPS ≥1%" />
                        </PageSubSection>
                        {/* HNSCC */}
                        <PageSubSection title={`${cancerTypes[5].modifier} ${cancerTypes[5].name}`}>
                            <Cutpoint indicationId={10} cutpoint="CPS ≥1" />
                        </PageSubSection>
                        {/* ESO or GEJ */}
                        <PageSubSection title={`${cancerTypes[12].modifier} ${cancerTypes[12].name}`}>
                            <Cutpoint indicationId={19} cutpoint="CPS ≥10" specification="Applies only to the 2L + monotherapy" />
                        </PageSubSection>

                        {/* CERVICAL */}
                        <PageSubSection title={`${cancerTypes[17].modifier} ${cancerTypes[17].name}`}>
                            <Cutpoint indicationId={28} cutpoint="CPS ≥1" />
                            <Cutpoint indicationId={24} cutpoint="CPS ≥1" />
                        </PageSubSection>
                        {/* TNBC */}
                        <PageSubSection title={`${indications[26].modifier} ${indications[26].name} (TNBC)`}>
                            <Cutpoint indicationId={26} cutpoint="CPS ≥10" />
                        </PageSubSection>
                    </PageSection>

                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>

                    <PageSection bgColor="mist">
                        <ReferencesBlock references={pageReferences} />
                    </PageSection>

                </TemplateColumn>
                {/* End Main Column */}
            </div>
        </DefaultLayout>
    );
};

export default Page;
